

































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Success extends Vue {
  created() {
    localStorage.setItem("registered", "1");
  }
}
