























































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Login extends Vue {
  private name = "Login";
  private username = "";
  private password = "";
  private loginFailed: boolean = false;

  login() {
    document.cookie = "activeSession=1; expires=Session; SameSite=None; Secure";
    let uri = "https://pro.reverberate.de/app/?stamp=" + Date.now();
    var http = new XMLHttpRequest();
    http.open("get", uri, false, this.username, this.password);
    http.send("");
    if (http.status === 200) {
      //location.reload();
      window.location.href = "https://pro.reverberate.de/app";
    } else {
      document.cookie =
        "activeSession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      this.loginFailed = true;
      this.username = "";
      this.password = "";
    }
  }
}
