

































































































import { Component, Vue } from "vue-property-decorator";
import Form from "@/components/Form.vue";
import Login from "@/components/Login.vue";

@Component({
  components: { Form, Login }
})
export default class Home extends Vue {
  openNewsletter() {
    setTimeout(
      () =>
        (this.$refs.newsletter as HTMLElement).scrollIntoView({
          block: "start",
          behavior: "smooth"
        }),
      320
    );
    setTimeout(
      () => ((this.$refs.loginDiv as HTMLElement).style.visibility = "hidden"),
      500
    );
    (this.$refs.newsletter as HTMLElement).style.visibility = "visible";
    (this.$refs.newsletter as HTMLElement).style.maxHeight = "900px";
    //(this.$refs.welcome as HTMLElement).style.maxHeight = "0px";
    (this.$refs.loginDiv as HTMLElement).style.maxHeight = "0px";
  }
  openLogin() {
    setTimeout(
      () =>
        (this.$refs.login as HTMLElement).scrollIntoView({
          block: "start",
          behavior: "smooth"
        }),
      55
    );
    (this.$refs.login as HTMLElement).style.visibility = "visible";
    (this.$refs.login as HTMLElement).style.maxHeight = "900px";
    (this.$refs.welcome as HTMLElement).style.maxHeight = "120px";
  }
  /*
  mounted() {
    if (localStorage.getItem("registered") === "1") {
      window.location.replace("./app");
    }
  }
  */
}
